import banner from "./video-banner.png";
import bottomCardPicOne from "./bottom-card1.png";
import bottomCardPicTwo from "./bottom-card2.png";
import bottomCardPicThree from "./bottom-card3.png";
import picleftOne from "./big-left-chair.png";
import picleftTwo from "./big-right.png";
import picleftThree from "./boat3.png";
import travelBanner from "./travel-banner.png";
import logoPic from "./logo.svg";
import whiteLogo from "./white-logo.png";
import roomOne from "./room1.png";
import motorBikeOne from "./motorbike1.png";
import motorBikeTwo from "./motorbike2.png";
import motorBikeThree from "./motorbike3.png";
import motorBikeFour from "./motorbike4.png";
import dayTripOne from "./daytrip1.png";
import dayTripTwo from "./daytrip2.png";
import dayTripThree from "./daytrip3.png";
import experience1 from "./experience1.png";
import experience2 from "./experience2.png";
import experience3 from "./experience3.png";
import experience4 from "./experience4.png";
import experience6 from "./experience6.png";
import experience7 from "./experience7.png";
import dakota from "./dakota.png";
import tasteBanner from "./taste-banner.png";
import offerOne from "./tas1.png";
import offerTwo from "./tas2.png";
import offerThree from "./tas3.png";
import taste1 from "./taste1.png";
import taste2 from "./taste2.png";
import taste3 from "./taste3.png";
import taste4 from "./taste4.png";
import travelBanner2 from "./travel-bann2.png";
import taste5 from "./taste5.png";
import motor1 from "./motor1.png";
import motor2 from "./motor2.png";
import motor3 from "./motor3.png";
import motor4 from "./motor4.png";
import motor5 from "./motor5.png";
import stayBanner from "./stay-baner.png";
import stayOne from "./stayOne.png";
import vsklImg from "./VSKL-3-3.png";
import stayTwo from "./stayTwo.png";
import stayThree from "./stayThree.png";
import stayFour from "./stayFour.png";
import hut from "./hut.png";
import yoga from "./yoga.png";
import statue from "./statue.png";
import vsklOne from "./vsklOne.png";
import vsklTwo from "./vsklTwo.png";
import vsklFive from "./vsklFive.png";
import vsklF from "./vsklF.png";
import mapImg from "./mapImg.png";
import vsklBanner from "./vskl-banner.png";
import vsklO from "./vsklO.png";
import vsklT from "./vsklT.png";
import closeIcon from "./closeIcon.png";
import celebtration from "./celebration.png";
import cruiseBanner from "./cruise-banner.png";
import celebrateOne from "./cele1.png";
import EventOne from "./eventOne.png";
import EventTwo from "./event2.png";
import EventThree from "./event3.png";
import vsklTasteBanner from "./vskl-taste-banner.png";
import EventFour from "./event4.png";
import vsklTaste from "./vvsk.png";
import jungleMan from "./jungle-man.png";
import singOne from "./sing1.png";
import singTwo from "./sing2.png";
import flower from "./flowercup.png";
import cooking from "./cooking.png";
import resturentNew from "./resturent.png";
import celebrationBanner from "./celebration-banner.png";
import townhouse from "./townhouse.png";
import picleftFour from "./picleftFour.png";
import experience8 from "./experience8.png";
import experience9 from "./experience9.png";
import whiteImg from "./white-img.png";
import LineOne from "./line1.png";
import LineTwo from "./line2.png";
import LineThree from "./line3.png";
import LineFour from "./line4.png";
import LineFive from "./line5.png";
import cruiseOne from "./cruise1.png";
import cruiseTwo from "./cruise2.png";
import LineSix from "./line6.png";
import Offer1 from "./offer1.png";
import Offer2 from "./offer2.png";
import Offer3 from "./offer3.png";
import exclusiveBanner from "./exclusive-bann.png";
import off1 from "./off1.png";
import off2 from "./off2.png";
import newShow from "./new-show.png";
import off3 from "./off3.png";
import crib1 from "../images/roomtype/crib-1.png";
import crib2 from "../images/roomtype/crib-2.png";
import crib3 from "../images/roomtype/crib-3.png";
import crib4 from "../images/roomtype/crib-4.png";
import crib5 from "../images/roomtype/crib-5.png";
import food from "../images/roomtype/column-1.png";
import RoomTypeBanner from "../images/room-type.png";
import other1 from "../images/other1.png";
import other2 from "../images/other2.png";
import moterbikeImg from "../images/roomtype/column-2.png";
import motorship from "../images/roomtype/column-5.png";
import vsklGallery from "../images/vskl-gallery.png";
import vsklgallery1 from "../images/vskl-gallery/vskl-gallery-1.png";
import vsklgallery2 from "../images/vskl-gallery/vskl-gallery-2.png";
import vsklgallery3 from "../images/vskl-gallery/vskl-gallery-3.png";
import VSKLThaipusamExperiencenew from "../images/vskl-expricence-banner.png";
import expricenceNew1 from "../images/expricence/ex-1.png";
import ex1 from "../images/expricence/ex-1.png";
import ex2 from "../images/expricence/ex-2.png";
import ex3 from "../images/expricence/ex-3.png";
import taste9 from "../images/taste9.png";
import taste8 from "../images/taste8.png";
import vsklonenewgallery from "../images/vskl-gallery/vskl-one.png";
import cruOne from "./cru1.png";
import cruTwo from "./cru2.png";
import experienceHome from "../images/expricence/experiences-home/expricence-banner.png";
import exslider1 from "../images/expricence/experiences-home/ex-slider-.png";
import exslider2 from "../images/expricence/experiences-home/ex-slider-2.png";
import exslider3 from "../images/expricence/experiences-home/ex-slider-3.png";
import eventslider1 from "../images/expricence/experiences-home/event-slider-1.png";
import eventslider2 from "../images/expricence/experiences-home/event-slider-2.png";
import eventslider3 from "../images/expricence/experiences-home/event-slider-3.png";
import cultreslider1 from "../images/expricence/experiences-home/cultre-slider-1.png";
import cultreslider2 from "../images/expricence/experiences-home/cultre-slider-2.png";
import discoverSlider1 from "../images/expricence/experiences-home/discover-1.png";
import discoverSlider2 from "../images/expricence/experiences-home/discover-2.png";
import discoverSlider3 from "../images/expricence/experiences-home/discover-3.png";
import story from "../images/expricence/experiences-home/story.png";
import cruThree from "./cru3.png";
import villasamadhi from "../images/villasamadhi.png";
import villasamadhi7473 from "../images/villasamadhi-7473.png";
import stroy11 from "../images/stroy11.png";
import vsklroombanner2 from "../images/vsklroombanner2.png";
import travelmoterbikebanner from "../images/travel-motorbike/travel-moterbikebanner.png";
import map from "../images/travel-motorbike/map.png";
import tour1 from "../images/travel-motorbike/tour-1.png";
import tour2 from "../images/travel-motorbike/tour-2.png";
import tour3 from "../images/travel-motorbike/tour-3.png";
import tour4 from "../images/travel-motorbike/tour-4.png";
import bike from "../images/bike.png";
import trv3 from "../images/trv-3.png";
import tasteNew from "../images/tastnew.png"
import stOne from "../images/st1.png"
import stTwo from "../images/st2.png"
import stThree from "../images/st3.png"
import ttOne from "../images/tt-1.png"

import ttTwo from "../images/tt-3.png"
import eventbannernew from "../images/eventbanner-new.png";
import charterBanner from "../images/charter-banner.png"
import cruiseCharterMap from "../images/cruise-map.png"
import building from "../images/building.png"
import ggOne from "../images/expricence/experiences-home/ggOne.png"
import aboutBanner from "../images/about/about-banner.png";
import aboutOne from "../images/about/aboutOne.png";
import abOne from "../images/about/ab1.png";
import abTwo from "../images/about/ab2.png";

import abThree from "../images/about/ab3.png";
import digitalPic from "../images/gift-card/digitalCard.png";
import aboutTwo from "../images/about/aboutTwo.png";
import aboutThree from "../images/about/aboutThree.png";

import aboutFour from "../images/about/aboutFour.png";

import aboutBoy from "../images/about/about-boy.png";
import bigOne from "../images/about/bigOne.png";
import smallOne from "../images/about/smallOne.png";
import bigTwo from "../images/about/bigTwo.png";
import smallTwo from "../images/about/smallTwo.png";
import yatchBanner from "../images/cruise-yatch/cru-yatch-banner.png";
import yatchNew from "../images/cruise-yatch/cru-yatch.png";
import truck from "../images/cruise-yatch/truck.png";
import contactOne from "../images/contact-us/contactOne.png";
import contactTwo from "../images/contact-us/contactTwo.png";
import jobBanner from "../images/job/jobBanner.png";
import tamarindOne from "../images/tttt.png";
import tamarindTwo from "../images/tttttt.png";
import footerLogo from "../images/footerLogo.png";

import celeOne from "../images/celebra-off1.png";
import celeTwo from "../images/celebra-off2.png";

import celeThree from "../images/celebra-off3.png";
import customerPic from "../images/customer-div.png";
import giftOne from "../images/gift-card/gift-slide-one.png";
import giftTwo from "../images/gift-card/gift-slide-two.png";
import accolBanner from "../images/accol-banner.png";
import detailBanner from "../images/retreat-detail.png";
import menuPic from "../images/menuPic.png";

const IMAGES = {
  tamarindOne,
  tamarindTwo,

  cruiseCharterMap,
  charterBanner,
  celebrateOne,
  banner,
  bottomCardPicOne,
  bottomCardPicTwo,
  bottomCardPicThree,
  picleftOne,
  picleftTwo,
  picleftThree,
  travelBanner,
  logoPic,
  whiteLogo,
  roomOne,
  motorBikeOne,
  motorBikeTwo,
  motorBikeThree,
  motorBikeFour,
  dayTripOne,
  dayTripTwo,
  dayTripThree,
  experience1,
  tasteBanner,
  experience2,
  experience3,
  experience4,
  experience6,
  experience7,
  experience9,
  RoomTypeBanner,
  vsklImg,
  offerOne,
  offerTwo,
  offerThree,
  taste1,
  taste2,
  taste3,
  taste4,
  taste5,
  travelBanner2,
  motor1,
  motor2,
  motor3,
  motor4,
  motor5,
  stayBanner,
  stayOne,
  stayTwo,
  mapImg,
  stayThree,
  stayFour,
  statue,
  yoga,
  hut,
  vsklOne,
  vsklTwo,
  vsklBanner,
  vsklO,
  vsklT,
  vsklFive,
  closeIcon,
  celebtration,
  cruiseBanner,
  EventOne,
  singOne,
  singTwo,
  EventTwo,
  EventThree,
  jungleMan,
  EventFour,
  cooking,
  flower,
  vsklTasteBanner,
  vsklTaste,
  resturentNew,
  celebrationBanner,
  townhouse,
  picleftFour,
  experience8,
  LineOne,
  LineTwo,
  LineFive,
  LineFour,
  LineThree,
  LineSix,
  dakota,
  cruiseOne,
  cruiseTwo,
  Offer1,
  Offer2,
  Offer3,
  whiteImg,
  crib1,
  crib2,
  crib3,
  crib4,
  crib5,
  food,
  exclusiveBanner,
  off1,
  off2,
  off3,
  newShow,
  other1,
  other2,
  moterbikeImg,
  motorship,
  vsklGallery,
  vsklgallery1,
  vsklgallery2,
  vsklgallery3,
  VSKLThaipusamExperiencenew,
  expricenceNew1,
  ex1,
  ex2,
  ex3,
  taste8,
  taste9,
  vsklonenewgallery,
  cruOne,
  cruTwo,
  cruThree,
  experienceHome,
  exslider1,
  exslider2,
  exslider3,
  eventslider1,
  eventslider2,
  eventslider3,
  cultreslider1,
  cultreslider2,
  discoverSlider1,
  discoverSlider2,
  discoverSlider3,
  story,
  villasamadhi,
  villasamadhi7473,
  stroy11,
  vsklroombanner2,
  vsklF,
  travelmoterbikebanner,
  map,
  tour1,
  tour2,
  tour3,
  tour4,
  bike,
  trv3,
  tasteNew,
  stOne,
  stTwo,
  stThree,
  ttOne,
  ttTwo,
  eventbannernew,
  building,
  ggOne,
  aboutBanner,
  aboutOne,
  abOne,
  abTwo,
  abThree,
  digitalPic,
  aboutTwo,
  aboutBoy,
  aboutFour,
  aboutThree,
  bigOne,
  smallOne,
  bigTwo,
  smallTwo,
  yatchNew,
  yatchBanner,
  truck,
  contactOne,
  contactTwo,
  jobBanner,
  footerLogo,
  celeOne,
  celeTwo,
  celeThree,
  customerPic,
  giftOne,
  giftTwo,
  accolBanner,
  detailBanner,
  menuPic,
};

export default IMAGES;
