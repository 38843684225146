import { Box, Container, Divider, Grid } from "@mui/material";
import { React, useEffect, useState } from "react";
import moment from "moment/moment.js";
import { Link, useNavigate } from "react-router-dom";
import { privacyDetail } from "../../api/frontend";
import { Helmet } from "react-helmet";

const Sustainability = () => {
  const navigate = useNavigate();
  const [privacydata, setPrivacyData] = useState("");
  const handlePrivacy = async () => {
    const response = await privacyDetail();

    if (response.remote === "success") {
      setPrivacyData(response.data.data[0]);
    } else {
    }
  };
  useEffect(() => {
    handlePrivacy();
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
    <Helmet>
    <meta charSet="utf-8" />
    <title>Boutique Hotels, Villas, Resorts & Residences | Samadhi Retreats</title>
    <meta name="description" content='Each Samadhi Retreats properties offer a unique sense of place, rooted in nature, culture and heritage. Discover more.'/>
</Helmet>
    <div className="crms accolades ">
      <div className="experience-bg pb-200">
        <Container maxWidth="xl">
          <div className="crms-heading position-relative  ">
            <h3 className="font-62">Sustainability</h3>
          </div>
        </Container>

        <Container maxWidth="xl">
          <div
            className="content-substainability"
            data-aos="fade-up"
            data-aos-once="true"
            data-aos-duration="1500"
          >
            <Grid container justifyContent="center">
              <Grid items xl={7} lg={9}>
                <>
                  <div className="accolades-list-div mt-3">
                    <h4 className="mb-3">Sustainability Policy</h4>
                    <p>
                      Samadhi Retreats properties are defining the future of the
                      hospitality and F&B industry through our passionate
                      commitment to environmental and societal change. Our
                      decisions are made to connect the elimination of waste,
                      environmental impact, and supporting our local
                      communities.
                    </p>
                    <p>
                      We are committed to producing zero waste at all of our
                      business establishments, eliminating plastic materials and
                      replacing them with sustainable and biodegradable
                      materials. We are using bamboo straws to serve beverages
                      and paper boxes for food takeaways.
                    </p>
                    <p>
                      Our produce is sourced from ethical local boutique farmers
                      where animals are allowed to freely roam outdoors, and
                      vegetables are grown in healthy organic soil. We also work
                      directly with fishermen to ensure that we are continually
                      adhering to best practices when it comes to sourcing
                      sustainable seafood. With these ingredients, our kitchen
                      and bar team creatively formulate recipes and creations
                      for a unique gastronomic dining experience.
                    </p>
                    <p>
                      We take great care in minimizing our environmental impact,
                      as such all our properties and furniture are built with
                      recycled salvaged timber. We are constantly striving to
                      improve our practices, and we seek to inspire change by
                      providing a Samadhi experience that enlivens your spirit.
                    </p>
                  </div>
                </>
              </Grid>
            </Grid>
          </div>
        </Container>
      </div>
    </div>
    </>
  );
};
export default Sustainability;
