import { React, useEffect, useState } from "react";
import Slider from "react-slick";
import IMAGES from "../../assets/images";
import OfferSlider from "../../components/OfferSlider";
import TestimonialSlider from "../../components/TestimonalSlider";
import { Grid } from "@mui/material";
import { Container } from "@mui/system";
import Carousel from "react-multi-carousel";
import { rightArrow } from "../../utils/svg.file";
import { Link, useNavigate } from "react-router-dom";
import { AboutSection, RetreatSection } from "../../api/frontend";
import LoaderMain from "../../admin/component/loader";
import { Helmet } from "react-helmet";
import { Offers_URL } from "../../serverUrl";
const {
  aboutBanner,
  aboutOne,
  abOne,
  abTwo,
  abThree,
  aboutTwo,
  aboutBoy,
  aboutFour,
  aboutThree,
  bigOne,
  smallOne,
  bigTwo,
  smallTwo,
} = IMAGES;
export default function AboutUs() {
  const navigate = useNavigate();
  const [isLoadingData, setIslodingData] = useState(true);
  const [aboutData, setAboutData] = useState('');
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  window.addEventListener("scroll", () => {
    let elem = document.getElementById("keyvisual");
    let scrollY = window.scrollY / 10;
    elem.style.backgroundSize = 100 + scrollY + "%";
  });

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 2,
      paritialVisibilityGutter: 60,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
      paritialVisibilityGutter: 60,
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 2,
      paritialVisibilityGutter: 50,
    },
    mobile: {
      breakpoint: { max: 600, min: 0 },
      items: 1,
      partialVisbile: false,
    },
  };
  
  const [retreatsDetailssection, setRetreatsDetailssection] = useState([]);
  const handleretreatsDetailssection = async () => {
    setIslodingData(true)
    const response = await RetreatSection();

    if (response.remote === "success") {
      setTimeout(() => {
        setIslodingData(false);
      }, 3000);
      setRetreatsDetailssection(response.data.data);
    } else {
    }
  };
  const handleaboutcontent = async () => {
    setIslodingData(true)
    const response = await AboutSection();

    if (response.remote === "success") {
      setTimeout(() => {
        setIslodingData(false);
      }, 3000);
      setAboutData(response.data.data);
    } else {
    }
  };
  const MotorBikeArray = [];
  retreatsDetailssection?.forEach((item, index) => {
      MotorBikeArray.push({
        image: item.cardImage,
        subcategory: "RETREATS",
        title: item.title,
        shortDescription: item.shortDescription,
        id: item.id,
        slug: item.slug,
        speed: "3000",
        dataspeed: "fade-up",
      });
  });
  useEffect(() => {
    handleretreatsDetailssection()
    handleaboutcontent()
  }, [])
  
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{aboutData.meta_title}</title>
        <meta name="description" content={aboutData.meta_description} />
      </Helmet>
      {isLoadingData ? <LoaderMain /> : ""}{" "}
      <div className="taste-page about-us">
        <div className=" main-bg">
          <div className="transparent-about-div">
            <Container maxWidth="xl" className="banner_container">
              <div
                className="banner-div"
                data-aos="fade-up"
                data-aos-duration="3000"
                // // data-aos-once="true"
              >
                <img src={`${Offers_URL}${aboutData.banner}`} alt="" />
              </div>
            </Container>
            <div className="page-description-div">
              <Container maxWidth="xl">
                <Grid container justifyContent="center">
                  <Grid
                    item
                    md={7}
                    className="page-description-data"
                    data-aos="fade-up"
                    data-aos-duration="1500"
                    // data-aos-once="true"
                  >
                    <p>{aboutData.about_text}</p>
                  </Grid>
                </Grid>
              </Container>
            </div>
            <div className="cards-big">
              <Container maxWidth="xl">
                <div className="normal-card-section">
                  <Grid container spacing={4}>
                    <Grid xs={12} item md={12} className="normal-card-div">
                      <Grid
                        alignItems="center"
                        container
                        spacing={10}
                        data-aos="fade-up"
                        data-aos-duration="3000"
                        // data-aos-once="true"
                        className="event-card-small mb-100"
                      >
                        <Grid item md={6} className="pt-0">
                          <img
                            src={`${Offers_URL}${aboutData.principal}`}
                            alt=""
                          />
                        </Grid>
                        <Grid item md={6} className="pt-0">
                          <div
                            className="cards heading detail-section me-5 cursor-btn"
                            onClick={() =>
                              navigate(
                                "/fine-dining-restaurants-kuala-lumpur-singapore"
                              )
                            }
                          >
                            <h3>Core Principles</h3>
                            <p className="">{aboutData.principal_text}</p>
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid xs={12} item md={12} className="normal-card-div mt-5">
                      <Grid
                        container
                        alignItems="center"
                        spacing={10}
                        data-aos="fade-up"
                        // data-aos-once="true"
                        data-aos-duration="3000"
                        className="event-card-small"
                      >
                        <Grid item md={6} className="pt-0">
                          <div className="cards heading detail-section">
                            <h3>State Of Mind</h3>
                            <p className="">{aboutData.mind_text}</p>
                          </div>
                        </Grid>
                        <Grid item md={6} className="pt-0">
                          <img src={`${Offers_URL}${aboutData.mind}`} alt="" />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
              </Container>
            </div>
            <div className="page-description-div">
              <Container maxWidth="xl">
                <Grid container justifyContent="center">
                  <Grid
                    item
                    md={7}
                    xs={12}
                    className="sense-of-place text-center"
                    data-aos="fade-up"
                    // data-aos-once="true"
                    data-aos-duration="1500"
                  >
                    <h3 className="geting-text">Sense Of Place</h3>
                    <p>{aboutData.place_text}</p>

                    {/* <Link to="#">
                      Make a Reservation{" "}
                      <span className="ms-2">{rightArrow}</span>
                    </Link> */}
                  </Grid>
                </Grid>
              </Container>
            </div>
            <div className="big-and-small-div">
              <Container maxWidth="xl">
                {" "}
                <Grid container spacing={4}>
                  <Grid
                    item
                    md={7}
                    xs={12}
                    data-aos="fade-up"
                    // data-aos-once="true"
                    data-aos-duration="1500"
                  >
                    <div className="big-div-card">
                      {" "}
                      <div className="img-section">
                        <img src={`${Offers_URL}${aboutData.people}`} alt="" />
                      </div>
                    </div>
                  </Grid>
                  <Grid
                    item
                    md={5}
                    xs={12}
                    data-aos="fade-up"
                    // data-aos-once="true"
                    data-aos-duration="1500"
                  >
                    <div className="small-div-card">
                      <div className="img-section">
                        <img
                          src={`${Offers_URL}${aboutData.people_sec}`}
                          alt=""
                        />
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Container>
            </div>
            <div className="cards-big">
              <Container maxWidth="xl">
                <div className="normal-card-section">
                  <Grid container spacing={4}>
                    <Grid xs={12} item md={12} className="normal-card-div mt-5">
                      <Grid
                        container
                        alignItems="center"
                        spacing={10}
                        data-aos="fade-up"
                        // data-aos-once="true"
                        data-aos-duration="3000"
                        className="event-card-small"
                      >
                        <Grid item md={6} className="pt-0">
                          <div className="cards heading detail-section">
                            <h3>Extending The Experience</h3>
                            <p className="">{aboutData.experience_text}</p>
                          </div>
                        </Grid>
                        <Grid item md={6} className="pt-0">
                          <img
                            src={`${Offers_URL}${aboutData.experience}`}
                            alt=""
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
              </Container>
            </div>{" "}
            <div className="page-description-div ab-privacy">
              <Container maxWidth="xl">
                <Grid container justifyContent="center">
                  <Grid
                    item
                    md={7}
                    className="about-privacy text-center"
                    data-aos="fade-up"
                    // data-aos-once="true"
                    data-aos-duration="1500"
                  >
                    <h3 className="geting-text">About Privacy</h3>
                    <p>{aboutData.privacy_text}</p>
                  </Grid>
                </Grid>
              </Container>
            </div>
            <div className="big-and-small-div with-text">
              <Container maxWidth="xl">
                {" "}
                <Grid container spacing={4}>
                  <Grid
                    item
                    md={7}
                    // data-aos-once="true"
                    data-aos="fade-up"
                    data-aos-duration="1500"
                  >
                    <div className="big-div-card">
                      {" "}
                      <div className="img-section">
                        <img src={`${Offers_URL}${aboutData.privacy}`} alt="" />
                      </div>
                      <div className="detail-section">
                        <h3>Eco-Luxe</h3>
                        <p>{aboutData.eco_text}</p>
                      </div>
                    </div>
                  </Grid>
                  <Grid
                    item
                    md={5}
                    data-aos="fade-up"
                    // data-aos-once="true"
                    data-aos-duration="1500"
                  >
                    <div className="small-div-card">
                      <div className="img-section">
                        <img
                          src={`${Offers_URL}${aboutData.privacy_sec}`}
                          alt=""
                        />
                      </div>
                      <div className="detail-section">
                        <h3>About Discovery</h3>
                        <p>{aboutData.discovery_text}</p>
                      </div>
                    </div>
                  </Grid>
                  <Grid item md={12}>
                    <div className="text-center">
                      {/* <Link to="#" className="mt-5">
                        Make a Reservation{" "}
                        <span className="ms-2">{rightArrow}</span>
                      </Link> */}
                    </div>
                  </Grid>
                </Grid>
              </Container>
            </div>{" "}
            <Container maxWidth="xl">
              <div className="cards-big last-new">
                <div className="normal-card-section">
                  <Grid container spacing={4}>
                    <Grid xs={12} item md={12} className="normal-card-div">
                      <Grid
                        alignItems="center"
                        container
                        spacing={10}
                        data-aos="fade-up"
                        // data-aos-once="true"
                        data-aos-duration="3000"
                        className="event-card-small"
                        style={{ marginBottom: "150px" }}
                      >
                        <Grid item md={6} className="pt-0">
                          <img src={aboutThree} alt="" />
                        </Grid>
                        <Grid item md={6} className="pt-0">
                          <div className="cards heading detail-section me-5">
                            <h3>Environmentally Conscious</h3>
                            <p className="">{aboutData.environmentally_text}</p>
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid xs={12} item md={12} className="normal-card-div ">
                      <Grid
                        container
                        alignItems="center"
                        spacing={10}
                        // data-aos-once="true"
                        data-aos="fade-up"
                        data-aos-duration="3000"
                        className="event-card-small "
                      >
                        <Grid item md={6} className="pt-0">
                          <div className="cards heading detail-section">
                            <h3>Supporting The Community </h3>
                            <p className="">{aboutData.community_text}</p>
                          </div>
                        </Grid>
                        <Grid item md={6} className="pt-0">
                          <img
                            src={`${Offers_URL}${aboutData.community}`}
                            alt=""
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
              </div>{" "}
            </Container>
          </div>
          <div className="day-trips-section">
            <Container maxWidth="xl">
              <Grid className="text-center section-head">
                <h3 className="geting-text">Other Properties</h3>
              </Grid>
              <div className="btn-slider-div">
                <Carousel
                  arrows={true}
                  ssr={true}
                  partialVisbile={true}
                  sliderClass="centerslider"
                  responsive={responsive}
                  autoPlay={false}
                  infinite={true}
                  className="mt-100"
                >
                  {MotorBikeArray.slice(0, 5).map((item, index) => {
                    return (
                      <div
                        className="slider__item "
                        data-aos={item.dataspeed}
                        data-aos-duration={item.speed}
                        // data-aos-once="true"
                        key={index}
                        onClick={() =>
                          navigate(`/${item.slug}?catagoryId=${item.id}`)
                        }
                      >
                        <OfferSlider {...item} />
                      </div>
                    );
                  })}
                </Carousel>
              </div>
            </Container>
          </div>
        </div>
      </div>
    </>
  );
}
