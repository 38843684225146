import { React, useEffect } from "react";
import SubHeader from "../../components/layout/SubHeader";
import { Grid } from "@mui/material";
import { Container } from "@mui/system";
import IMAGES from "../../assets/images";
import OfferSlider from "../../components/OfferSlider";
import TestimonialSlider from "../../components/TestimonalSlider";
import { ParallaxBanner, ParallaxBannerLayer } from "react-scroll-parallax";
import Slider from "react-slick";
import { Row, Col, Button } from "react-bootstrap";
import { rightArrow, subMenuArrow } from "../../utils/svg.file";
import MapSidebar from "./mapSidebar";
import { useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import { Diversity1Rounded } from "@mui/icons-material";
import BannerSlider from "../../components/BannerSlider";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import {
  geetingDetailsData,
  retreatsDetailsData,
  retreatsGalleryDetailsData,
} from "../../api/frontend";
import LoaderMain from "../../admin/component/loader";
import { Helmet } from "react-helmet";
const {
  travelBanner2,
  motor1,
  motor2,
  motor3,
  motor4,
  motor5,
  vsklOne,
  stayOne,
  vsklTwo,
  vsklT,
  vsklO,
  vsklTaste,
  vsklBanner,
  jungleMan,
  vsklTasteBanner,
  resturentNew,
  taste3,
  cooking,
} = IMAGES;
const BannerArray = [vsklTasteBanner, vsklBanner];
const restaurentList = [
  {
    image: resturentNew,
    type: "TASTES",
    title: "Tamarind Hill, Kuala Lumpur",
    description:
      "Planning the perfect celebration begins with selecting your dream venue. Encircled by the raw beauty of nature, this jungle-luxe restaurant’s long meditative walkway, treetop vistas, water features and stone-carved statues exude Asian elegance and a warm and welcoming ambience.",
    id: "1",
    speed: "1000",
    dataspeed: "fade-up",
  },
  {
    image: taste3,
    type: "TASTES",
    title: "Fook",
    description:
      "At Samadhi we pride ourselves on being an eco-conscious organisation and we’ve been that way since our inception decades ago. Committed to preserving nature and protecting the health of our planet, we ensure that our properties are created from sustainable… ",
    id: "2",
    speed: "2000",
    dataspeed: "fade-down",
  },
  {
    image: vsklTwo,
    type: "TASTES",
    title: "Tamarind Springs",
    description:
      "Set among the leafy groves of KL’s affluent embassy district, Villa Samadhi is the perfect urban escape…",
    id: "3",
    speed: "3000",
    dataspeed: "fade-up",
  },
];
const tasteList = [
  {
    image: vsklOne,
    type: "OFFERS",
    title: "Samadhi Indulgence",
    description:
      "The ultimate treat for two. With inclusions that are meant to indulge our guests, immerse yourself in a transcendental Samadhi Experience like no other from food and beverages..",
    id: "1",
    speed: "1000",
    dataspeed: "fade-up",
  },
  {
    image: vsklTaste,
    type: "SOMA WELLNESS",
    title: "A wellness spa in the heart of the wild",
    description:
      "SOMA is your source of wellness. A health system that embraces a holistic approach to total healing of mind, body and soul through a combination of ancient yogic practices",
    id: "2",
    speed: "2000",
    dataspeed: "fade-down",
  },
  {
    image: jungleMan,
    type: "STORY",
    title: "The Thaipusam Experience",
    description:
      "Witness the chaos and wonder of Thaipusam, the Hindu Tamil festival. Follow the throng of bare-foot devotees as they make their temple pilgrimage accompanied by rhythmic music, ",
    id: "3",
    speed: "3000",
    dataspeed: "fade-up",
  },
  {
    image: cooking,
    type: "STORY",
    title: "Street Foods",
    description:
      "Witness the chaos and wonder of Thaipusam, the Hindu Tamil festival. Follow the throng of bare-foot devotees as they make their temple pilgrimage accompanied by rhythmic music,",
    id: "1",
    speed: "1000",
    dataspeed: "fade-up",
  },
];
export default function VSKLTaste() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [retreatIds, setRetreatsid] = useState("");
  const [retreatsslug, setRetreatsslug] = useState("");
  const [retreatsLocation, setRetreatsLocation] = useState("");
  const [retreatsaccomodationBanner, setRetreatsaccomodationBanner] = useState(
    []
  );
  const [retreatsaccomodationDescription, setRetreatsaccomodationDescription] =
    useState("");
  const [retreatsTitle, setRetreatsTitle] = useState("");
  const [retreatsDiningTitle, setRetreatsDiningTitle] = useState("");
  const [retreatsRoomArray, setRetreatsRoomArray] = useState([]);
  const [exclusiveOffers, setExclusiveOffers] = useState([]);
  const [isLoadingData, setIslodingData] = useState(true);
  const [retreatsMetaTitleData, setRetreatsMetaTitleData] = useState("");
  const [retreatsMetaDiscriptionData, setRetreatsMetaDiscriptionData] =
    useState("");
  const handleretreatsDetails = async (catagoryId) => {
    setIslodingData(true);

    const response = await retreatsGalleryDetailsData(catagoryId);

    if (response.remote === "success") {
      setTimeout(() => {
        setIslodingData(false);
      }, 3000);
      setExclusiveOffers(response.data.exclusive);
      setRetreatsaccomodationBanner(
        JSON.parse(response.data.data[0].dining_gallery)
      );
      setRetreatsaccomodationDescription(
        response.data.data[0].dining_description
      );
      setRetreatsid(response.data.data[0].id);
      setRetreatsslug(response.data.data[0].slug);
      setRetreatsTitle(response.data.data[0].title);
      setRetreatsLocation(response.data.data[0].other);
      setRetreatsDiningTitle(response.data.data[0].dining_title);
      setRetreatsMetaTitleData(response.data.data[0].meta_title);
      setRetreatsMetaDiscriptionData(response.data.data[0].meta_description);
    } else {
    }
  };

  const handleretRoomreatsDetails = async (catagoryId) => {
    setIslodingData(true);

    const response = await retreatsDetailsData(catagoryId);

    if (response.remote === "success") {
      setTimeout(() => {
        setIslodingData(false);
      }, 3000);

      setRetreatsRoomArray(response.data.roomArray);
    } else {
    }
  };

  const exclusiveOffer = [];
  exclusiveOffers?.map((item) => {
    exclusiveOffer.push({
      image: item.image,
      subcategory: "OFFERS",
      title: item.title,
      shortDescription: item.shortDescription,
      id: item.id,
      other: item.other,
      speed: "1000",
      dataspeed: "fade-up",
    });
  });
  const [gettingData, setGettingData] = useState([]);

  const handlegettingDetails = async (catagoryId) => {
    setIslodingData(true);

    const response = await geetingDetailsData(catagoryId);

    if (response.remote === "success") {
      setTimeout(() => {
        setIslodingData(false);
      }, 3000);
      setGettingData(response.data.data[0]);
    } else {
    }
  };
  useEffect(() => {
    const catagoryId = searchParams.get("retreatId");
    handleretreatsDetails(catagoryId);
    handlegettingDetails(catagoryId);
    handleretRoomreatsDetails(catagoryId);
  }, [searchParams]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
      paritialVisibilityGutter: 60,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      paritialVisibilityGutter: 60,
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 2,
      paritialVisibilityGutter: 50,
    },
    mobile: {
      breakpoint: { max: 600, min: 0 },
      items: 1,
      partialVisbile: false,
    },
  };
  return (
    <>
    <Helmet>
      <meta charSet="utf-8" />
      <title>{retreatsMetaTitleData}</title>
      <meta name="description" content={retreatsMetaDiscriptionData} />

          <script src="https://static.sojern.com/utils/sjrn_autocx.js"></script>
          <script type="text/javascript">{`
                       (function () {
      var params = {
          hpr: "Villa Samadhi Kuala Lumpur",
          hpid: "45395412-8114-11e5-8827-02b1347ffa5b",
          sha256_eml: "",
          sha1_eml: "",
          md5_eml: "",
          ccid: "",
          pt: "TRACKING"
      };

      params.et = {"HOME_PAGE":null,"SEARCH":"hs","PRODUCT":"hpr","SHOPPING_CART":"hcart","CONVERSION":"hc","TRACKING":null}[params.pt] || '';
      try{params = Object.assign({}, sjrn_params, params);}catch(e){}
      var paramsArr = [];
      for(var key in params) { paramsArr.push(key + '=' + encodeURIComponent(params[key])) };

      var pl = document.createElement('iframe');
      pl.type = 'text/html';
      pl.setAttribute('style','height:0; width: 0; display:none;');
      pl.async = true;
      pl.src = "https://static.sojern.com/cip/c/206.html?f_v=cp_v3_js&p_v=1&" + paramsArr.join('&');
      (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(pl);
  })();
                  `}</script>

    </Helmet>
      {isLoadingData ? <LoaderMain /> : ""}

      <div className="travel-category vskl-taste">
        <div className=" main-bg">
          <Container maxWidth="xl">
            <div className="sub-header-div">
              <div className="sub-header">
                <Row className="g-0">
                  <Col
                    lg={6}
                    className="d-flex justify-content-md-end  justify-content-sm-center sub-header-title"
                  >
                    <h3
                      className="cursor-btn"
                      onClick={() =>
                        navigate(`/${retreatsslug}?catagoryId=${retreatIds}`)
                      }
                    >
                      {retreatsTitle && retreatsTitle !== undefined
                        ? `${retreatsTitle}, ${retreatsLocation}`
                        : ""}

                      <span className="ms-2">{subMenuArrow}</span>
                    </h3>
                  </Col>
                  <Col lg={6}>
                    <ul
                      className="ps-0 pt-20
                    "
                    >
                      <li>
                        {/* <Link to="#" className="active">{item.subLink}</Link> */}
                        <Button
                          onClick={() =>
                            navigate(
                              `/${retreatsslug}/rooms?retreatId=${retreatIds}`
                            )
                          }
                        >
                          Rooms
                        </Button>
                      </li>
                      <li>
                        {/* <Link to="#" className="active">{item.subLink}</Link> */}
                        <Button
                          className="active"
                          onClick={() =>
                            navigate(
                              `/${retreatsslug}/dining?retreatId=${retreatIds}`
                            )
                          }
                        >
                          {" "}
                          Dining
                        </Button>
                      </li>
                      {/* <li>
                        <Button
                          onClick={() =>
                            navigate(`/villa-samadhi-kuala-lumpur/gallery?retreatId=${retreatIds}`)
                          }
                        >
                          Experience
                        </Button>
                      </li> */}
                      <li>
                        {/* <Link to="#" className="active">{item.subLink}</Link> */}
                       {retreatIds !== 18 ? <>
                    <Button
                      onClick={() =>
                        navigate(
                          `/vskl-celebration?retreatId=${retreatIds}`
                        )
                      }
                    >
                      {" "}
                      Wellness
                    </Button>
				  </>:""}
                      </li>
                      <li>
                        {/* <Link to="#" className="active">{item.subLink}</Link> */}
                        <Button
                          onClick={() =>
                            navigate(
                              `/exclusive-offer-details?retreatId=${retreatIds}&type=retreats`
                            )
                          }
                        >
                          Offers
                        </Button>
                      </li>
                      <li>
                        {/* <Link to="#" className="active">{item.subLink}</Link> */}
                        <Button
                          onClick={() =>
                            navigate(
                              `/${retreatsslug}/gallery?retreatId=${retreatIds}`
                            )
                          }
                        >
                          Gallery
                        </Button>
                      </li>
                      <li>
                        <Button onClick={() => navigate(`/travels-tours`)}>
                          Activities
                        </Button>
                      </li>
                      {/* {gettingData ? (
                        <li>
                          <Button
                            onClick={() =>
                              navigate(
                                `/${retreatsTitle}/getting-here?retreatId=${retreatIds}`
                              )
                            }
                          >
                            Getting Here
                          </Button>
                        </li>
                      ) : (
                        ""
                      )} */}
                    </ul>
                  </Col>
                </Row>
              </div>
            </div>
          </Container>
          <Container maxWidth="xl" className="banner_container">
            <div className="banner-div pb-100 ">
              <h2 className="text-center  ">
                {retreatsDiningTitle !== "null" ? retreatsDiningTitle : ""}
              </h2>
              <div
                data-aos="fade-up"
                data-aos-duration="3000"
                data-aos-once="true"
              >
                <BannerSlider
                  BannerArray={retreatsaccomodationBanner}
                  data-aos="fade-up"
                  data-aos-duration="3000"
                  // data-aos-once="true"
                />
              </div>
              <div
                className="text-center mt-5"
                data-aos="fade-up"
                data-aos-duration="1500"
                data-aos-once="true"
              >
                <Grid container spacing={3} justifyContent="center">
                  <Grid item md={8}>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: retreatsaccomodationDescription,
                      }}
                    />
                  </Grid>
                </Grid>
              </div>
            </div>
          </Container>
          <div className="day-trips-section ">
            <Container maxWidth="xl">
              <div className="day-trip-section-bordered  ">
                <div className="btn-slider-div">
                  <Carousel
                    autoPlaySpeed={1200}
                    arrows={true}
                    ssr={true}
                    partialVisbile={exclusiveOffer.length < 4 ? false : true}
                    // sliderClass="centerslider"
                    responsive={responsive}
                    autoPlay={false}
                    infinite={false}
                  >
                    {exclusiveOffer.slice(0, 5).map((item, index) => (
                      <div
                        key={index}
                        className="bottom-cards-div  cursor-btn"
                        data-aos={item.dataspeed}
                        data-aos-duration={item.speed}
                        data-aos-once="true"
                        onClick={() => navigate(`/offers/${item.slug}`)}
                      >
                        <OfferSlider {...item} />
                      </div>
                    ))}
                  </Carousel>
                </div>
              </div>
            </Container>
          </div>
          {/* <div className="day-trips-section restaurent-div">
            <Container maxWidth="xl">
              <div className="day-trip-section-bordered btn-slider-div">
                <Grid className="text-center section-head">
                  <h3 className="geting-text">Other restaurants nearby</h3>
                </Grid>
                <Carousel
                  autoPlaySpeed={1200}
                  arrows={true}
                  ssr={true}
                  partialVisbile={true}
                  sliderClass="centerslider"
                  responsive={responsiveRestaurent}
                  autoPlay={false}
             infinite={true}
                  className="mt-150"
                >
                  {restaurentList.slice(0, 5).map((item, index) => (
                    <div
                      key={index}
                      className="bottom-cards-div  cursor-btn"
                      data-aos={item.dataspeed}
                      data-aos-duration={item.speed}
                      onClick={() => navigate("/tastes-kl-tamarind-springs")}
                    >
                      <OfferSlider {...item} />
                    </div>
                  ))}
                </Carousel>
              </div>
            </Container>
          </div> */}
          <Container maxWidth="xl">
            <div className="book-offer-div">
              <Grid
                container
                spacing={3}
                className="text-center section-head align-items-center"
              >
                <Grid md={3}></Grid>
                <Grid md={3}>
                  <h3>Offers</h3>
                </Grid>
                <Grid md={3}>
                  <Button
                    className="white-btn"
                    onClick={() =>
                      navigate(
                        `/exclusive-offer-details?retreatId=${retreatIds}&type=retreats`
                      )
                    }
                  >
                    Book Now
                  </Button>
                </Grid>
              </Grid>
            </div>
          </Container>
        </div>
      </div>
    </>
  );
}
