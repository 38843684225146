import {React,useEffect,useState } from 'react'
import SubHeader from '../../components/layout/SubHeader'
import { Grid } from "@mui/material";
import { Container } from "@mui/system";
import IMAGES from "../../assets/images";
import OfferSlider from "../../components/OfferSlider";
import Slider from "react-slick";
import BannerSlider from "../../components/BannerSlider";
import ExclusiveCard from "./ExclusiveCard";
import { Link } from "react-router-dom";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useNavigate } from "react-router-dom";
import { exclusiveoffers, exclusiveoffersdetails } from "../../api/frontend";
import { useParams } from "react-router-dom";
import LoaderMain from "../../admin/component/loader";
import { Helmet } from "react-helmet";
import { Col, Row } from "react-bootstrap";
import { subMenuArrow } from "../../utils/svg.file";
import { Button } from "react-bootstrap";

const {
  exclusiveBanner,
  off1,
  off2,
  off3,
  vsklOne,
  stayOne,
  vsklTwo,
  vsklT,
  vsklO,
  vsklTaste,
  vsklBanner,
  jungleMan,
  vsklTasteBanner,
  resturentNew,
  building,
  taste3,
  cooking,
} = IMAGES;

export default function VSKLExclusiveOffer() {
  const navigate = useNavigate();
  const [restaurentList, setRestaurentList] = useState([]);
  const [exclusiveOfferArray, setExclusiveOfferArray] = useState([]);
  const [ticketData, setTicketData] = useState([]);
  const [exclusiveTitle, setExclusiveTitle] = useState("");
  const [exclusivebooking, setExclusivebooking] = useState("");
  const [isLoadingData, setIslodingData] = useState(true);

  let { id } = useParams();

  const handleexclusive = async () => {
    setIslodingData(true);

    const response = await exclusiveoffers();

    if (response.remote === "success") {
      setTimeout(() => {
        setIslodingData(false);
      }, 3000);
      setRestaurentList(response.data.data);
    } else {
    }
  };
  const handleexclusivedetails = async () => {
    setIslodingData(true);

    const showexclusivePayload = {
      slug: id,
    };
    const response = await exclusiveoffersdetails(showexclusivePayload);

    if (response.remote === "success") {
    
       setTicketData(response.data.ticket)
      setTimeout(() => {
        setIslodingData(false);
      }, 3000);
      setExclusiveOfferArray(response.data.data);
      setExclusiveTitle(response.data.title);
      setExclusivebooking(response.data.booking);
    } else {
    }
  };
  useEffect(() => {
    handleexclusive();
  }, []);
  useEffect(() => {
    handleexclusivedetails();
    window.scrollTo(0, 0);
  }, [id]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
      paritialVisibilityGutter: 60,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      paritialVisibilityGutter: 60,
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 2,
      paritialVisibilityGutter: 50,
    },
    mobile: {
      breakpoint: { max: 600, min: 0 },
      items: 1,
      partialVisbile: false,
      
    },
  };
  // const onChange = ((type)=>{
  //   handleexclusiveCategory()
  //   setCountrytype(type);
  // });
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{exclusiveOfferArray.meta_title}</title>
        <meta
          name="description"
          content={exclusiveOfferArray.meta_description}
        />
      </Helmet>
      {isLoadingData ? <LoaderMain /> : ""}

      <div className="travel-category vskl-ex-offer vskl-taste">
        <div className=" main-bg">
          <Container maxWidth="xl">
            <div className="sub-header-div">
              {/* <SubHeader
                pageTitle="Offers"
                subHeading={[
                  {
                    id: "0",
                    subLink: "Malaysia",
                    subUrl: "/exclusive-offers",
                  },
                  {
                    id: "1",
                    subLink: "Singapore",
                    subUrl: "/exclusive-offers",
                  },
                ]}
              /> */}
              <div className="sub-header">
                <Row className="g-0">
                  <Col
                    lg={6}
                    className="d-flex justify-content-md-end  justify-content-sm-center sub-header-title"
                  >
                    <h3>
                      Offers
                      <span className="ms-2">{subMenuArrow}</span>
                    </h3>
                  </Col>
                  {/* <Col lg={6}>
                    <ul className="ps-0 pt-20 ">
                      <li>
                        <Button
                          onClick={() => navigate("/offers?state=malaysia")}
                          // onClick={() => onChange("malaysia")}
                          className={
                            exclusiveOfferArray.country === "malaysia"
                              ? "active"
                              : ""
                          }
                        >
                          Malaysia
                        </Button>
                      </li>
                      <li>
                        <Button
                          onClick={() => navigate("/offers?state=singapore")}
                          className={
                            exclusiveOfferArray.country === "singapore"
                              ? "active"
                              : ""
                          }
                        >
                          Singapore
                        </Button>
                      </li>
                    </ul>
                  </Col> */}
                </Row>
              </div>
            </div>
          </Container>
          ,
          <div className="">
            <Container maxWidth="xl">
              <div className="exclusive-newnew">
                <Grid className="text-center section-head">
                  <h3 className="geting-text">
                    {" "}
                    Offers - {exclusiveOfferArray.title}
                  </h3>
                  {/* <h3 className="geting-text">{exclusiveTitle ? `Offers - ${exclusiveTitle}` : ''}</h3> */}
                </Grid>
                {/* {exclusiveOfferArray?.map((item, index) => ( */}
                <div
                  // key={index}
                  // data-aos={item.dataspeed}
                  // data-aos-duration={item.speed}
                  className="bottom-cards-div "
                >
                  <ExclusiveCard {...exclusiveOfferArray} booking={exclusivebooking} ticketData={ticketData.length} ticketSlug={ticketData[0]?.other} />
                </div>
                {/* ))} */}
                <div className="text-center mt-100">
                  {" "}
                  <Link
                    to={`/exclusive-offer-details?retreatId=${exclusiveOfferArray.locationId}&type=${exclusiveOfferArray.offerType}`}
                    className="all-show-btn text-center"
                    style={{ textDecoration: "none" }}
                  >
                    {exclusiveTitle
                      ? `View all offers for ${exclusiveTitle}`
                      : ""}
                  </Link>
                </div>
              </div>
            </Container>
          </div>
          <div className=" other-div">
            <Container maxWidth="xl">
              <div className="btn-slider-div">
                <Carousel
                  autoPlaySpeed={1200}
                  arrows={true}
                  ssr={true}
                  partialVisbile={true}
                  sliderClass="centerslider"
                  responsive={responsive}
                  autoPlay={false}
                  infinite={true}
                  className="mt-100"
                >
                  {restaurentList
                    .filter(
                      (el) =>
                        el.locationId === exclusiveOfferArray.locationId &&
                        el.offerType === exclusiveOfferArray.offerType
                    )
                    .map((content, index) => {
                      var gotIndex = index % 2 == 0;
                      return gotIndex ? (
                        <>
                          <div
                            onClick={() => navigate(`/offers/${content.slug}`)}
                            className="normal-card-div cursor-btn"
                            key={content.id}
                            data-aos="fade-up"
                            data-aos-once="true"
                            data-aos-duration={gotIndex * 1000}
                          >
                            <OfferSlider {...content} />
                          </div>
                        </>
                      ) : (
                        <div
                          onClick={() => navigate(`/offers/${content.slug}`)}
                          className="normal-card-div  cursor-btn"
                          key={content.id}
                          data-aos="fade-down"
                          data-aos-once="true"
                          data-aos-duration={gotIndex * 1000}
                        >
                          <OfferSlider {...content} />
                        </div>
                      );
                    })}
                </Carousel>
                <div className="text-center">
                  {" "}
                  <Link to="/offers" className="all-show-btn text-center">
                    View all offers
                  </Link>
                </div>
              </div>
            </Container>
          </div>
        </div>
      </div>
    </>
  );
}
