import { React, useEffect, useState } from "react";
import SubHeader from "../../components/layout/SubHeader";
import { Grid } from "@mui/material";
import { Container } from "@mui/system";
import IMAGES from "../../assets/images";
import OfferSlider from "../../components/OfferSlider";
import BannerSlider from "../../components/BannerSlider";
import TabButton from "./TabButton";
import TestimonialSlider from "../../components/TestimonalSlider";
import { ParallaxBanner, ParallaxBannerLayer } from "react-scroll-parallax";
import Slider from "react-slick";
import styles from "./celebration.module.css";
import {
  showfeaturedstory,
  celebrationBanners,
  celebrationBlock,
  celebrationHomeText,
} from "../../api/frontend";
import { useNavigate, useSearchParams } from "react-router-dom";
import LoaderMain from "../../admin/component/loader";
import { Offers_URL } from "../../serverUrl";
import { Featured_URL } from "../../serverUrl";
import { Helmet } from "react-helmet";
import { Col, Row } from "react-bootstrap";
const {
  customerPic,
  celebrateOne,
  stayBanner,
  vsklBanner,
  motor1,
  motor2,
  motor3,
  motor4,
  motor5,
  experience1,
  experience3,
  celebrationBanner,
  experience8,
  LineOne,
  LineTwo,
  LineFive,
  LineFour,
  LineThree,
  LineSix,
} = IMAGES;

export default function CelebrationEvent() {
  const navigate = useNavigate();
  const [restaurantId, setRestaurantId] = useState("");
  const [celebrationText, SetcelebrationText] = useState("");
  const [searchParams] = useSearchParams();
  const [experienceArray, SetexperienceArray] = useState([]);
  const [BannerArray, setBannerArray] = useState([]);
  const [BlockCelebration, setCelebrationBlock] = useState([]);
  const [isLoadingData, setIslodingData] = useState(true);

  const handleCelebrationBanner = async () => {
    setIslodingData(true);
    const response = await celebrationBanners();

    if (response.remote === "success") {
      setTimeout(() => {
        setIslodingData(false);
      }, 3000);
      setBannerArray(response.data.data);
    } else {
    }
  };
  const handleCelebrationBlock = async () => {
    setIslodingData(true);

    const response = await celebrationBlock();

    if (response.remote === "success") {
      setTimeout(() => {
        setIslodingData(false);
      }, 3000);
      setCelebrationBlock(response.data.data);
    } else {
    }
  };

  useEffect(() => {
    handleCelebrationBanner();
    handleCelebrationBlock();
  }, []);

  let rows = [];

  BlockCelebration?.map((item, index) => {
    rows.push({
      image: item.cardimage,
      type: "CELEBRATIONS & EVENTS",
      title: item.title,
      shortDescription: item.description,
      id: item.id,
      speed: "fade-up",
      dataspeed: "1500",
      other: item.other,
    });
  });
  var experienceSlide = {
    arrows: false,
    dots: true,
    infinite: true,
    speed: 500,

    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const handlefeaturedstory = async () => {
    setIslodingData(true);

    const response = await showfeaturedstory();

    if (response.remote === "success") {
      setTimeout(() => {
        setIslodingData(false);
      }, 3000);
      SetexperienceArray(response.data.data);
    } else {
    }
  };
  const handlecelebrationText = async () => {
    setIslodingData(true);

    const response = await celebrationHomeText();

    if (response.remote === "success") {
      setTimeout(() => {
        setIslodingData(false);
      }, 3000);
      SetcelebrationText(response.data.data.text);
    } else {
    }
  };
  useEffect(() => {
    const catagoryId = searchParams.get("catagoryId");
    setRestaurantId(catagoryId);
  }, [searchParams]);

  useEffect(() => {
    handlefeaturedstory();
    handlecelebrationText();
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Weddings & Events | Venues Kuala Lumpur | Samadhi Retreats
        </title>
        <meta
          name="description"
          content="From private executive meetings to breathtaking jungle-enclave weddings, discover versatile event spaces  that's perfect for you."
        />
      </Helmet>
      {isLoadingData ? <LoaderMain /> : ""}
      <div className="celebration-and-event-page celebration-home">
        <div className=" main-bg">
          <div className="sub-header">
            <Row className="g-0">
              <Col
                lg={12}
                className="d-flex justify-content-md-center  justify-content-sm-center sub-header-title"
              >
                <h3>Celebrations & Events</h3>
              </Col>
            </Row>
          </div>
          <Container maxWidth="xl" className="banner_container">
            <div
              className="banner-div"
              data-aos="fade-up"
              data-aos-duration="3000"
              // data-aos-once="true"
            >
              <BannerSlider BannerArray={BannerArray} />
            </div>
          </Container>

          <div className="celebration_cards_main">
            <Container maxWidth="xl">
              <div
                className="mt-4 "
                data-aos="fade-up"
                data-aos-once="true"
                data-aos-duration="1500"
              >
                {/* <TabButton /> */}
              </div>
              <div
                className="landing-page-content text-center pt-3 pb-2"
                style={{ color: "#d9ccc5" }}
              >
                <p
                  style={{ color: "#d9ccc5" }}
                  dangerouslySetInnerHTML={{ __html: celebrationText }}
                />
              </div>
              <div className="normal-card-section">
                <Grid container spacing={4}>
                  {rows?.map((content, index) => {
                    var gotIndex = index % 2 == 0;
                    return gotIndex ? (
                      <>
                        <Grid
                          xs={12}
                          item
                          md={6}
                          className="normal-card-div"
                          key={content.id}
                          data-aos="fade-up"
                          data-aos-duration="2000"
                          data-aos-once="true"
                          onClick={() =>
                            navigate(`/celebration-event/${content.other}`)
                          }
                        >
                          <OfferSlider {...content} />
                        </Grid>
                      </>
                    ) : (
                      <>
                        <Grid
                          xs={12}
                          item
                          md={6}
                          className="normal-card-div"
                          key={content.id}
                          data-aos="fade-up"
                          data-aos-duration="1500"
                          data-aos-once="true"
                          onClick={() =>
                            navigate(`/celebration-event/${content.other}`)
                          }
                        >
                          <OfferSlider {...content} />
                        </Grid>
                      </>
                    );
                  })}
                </Grid>
              </div>
            </Container>
          </div>

          <div className="experience-div-section">
            <Container maxWidth="xl">
              <Grid className="text-center section-head">
                <h3>Stories</h3>
              </Grid>
              <Slider
                {...experienceSlide}
                className="experience-slider celebrate-customer-div"
              >
                {experienceArray.map((item, index) => (
                  <Grid className="testimonial-type-card">
                    <Grid
                      container
                      spacing={{ md: 6, xs: 2 }}
                      className="align-items-center d-flex "
                      data-aos="fade-up"
                      data-aos-duration="10000"
                      alignItems="center"
                    >
                      <Grid item xs={12} md={4}>
                        <div>
                          {" "}
                          <Grid className="cards heading">
                            <h3>{item.discription}</h3>
                            <h3
                              className="mt-5"
                              style={{ fontStyle: "normal" }}
                            >
                              {item.heading}
                            </h3>
                          </Grid>
                        </div>
                      </Grid>
                      <Grid item xs={12} md={8}>
                        <img src={`${Featured_URL}${item.image}`} alt="" />
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              </Slider>
            </Container>
          </div>
        </div>
      </div>
    </>
  );
}
