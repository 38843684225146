import { React, useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { Container } from "@mui/system";
import OfferSlider from "../../components/OfferSlider";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Slider from "react-slick";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import {
  exclusiveofferswithId,
  showExperienceIdsList,
  TravelBannerSection,
  TravelCategorySection,
  TravelSection,
} from "../../api/frontend";
import { Offers_URL } from "../../serverUrl";
import LoaderMain from "../../admin/component/loader";
import { Helmet } from "react-helmet";
import { Col, Row } from "react-bootstrap";

export default function Travels() {
  const navigate = useNavigate();
  const [bannerList, setbannerList] = useState([]);
  const [travelArray, setTravelArray] = useState([]);
  const [travelcategoryArray, setTravelCategory] = useState([]);
  const [travelPackage, setTravelPackage] = useState([]);
  const [travelId, setTravelId] = useState("");
  const [MotorBikeArray, setMotorBikeArray] = useState([]);
  const [searchParams] = useSearchParams();
  const [isLoadingData, setIslodingData] = useState(true);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    handleexclusive();
    const catagoryId = searchParams.get("catagoryId");
    setTravelId(catagoryId);
    const MotorBikeArray = [];
    const idMatchData = [];
    travelPackage?.forEach((item) => {
      if (item.categoryId === catagoryId) {
        idMatchData.push({
          image: item.image,
          subcategory: item.category,
          title: item.title,
          shortDescription: item.discription,
          id: item.id,
        });
      } else {
        MotorBikeArray.push({
          image: item.image,
          subcategory: item.category,
          title: item.title,
          shortDescription: item.discription,
          id: item.id,
        });
      }
    });
    if (catagoryId) {
      setMotorBikeArray([...idMatchData]);
    } else {
      setMotorBikeArray([...MotorBikeArray]);
    }
  }, [searchParams, travelPackage]);

  var experienceSlide = {
    arrows: false,
    dots: true,
    infinite: true,
    speed: 500,

    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
      paritialVisibilityGutter: 15,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      paritialVisibilityGutter: 15,
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 2,
      paritialVisibilityGutter: 15,
    },
    mobile: {
      breakpoint: { max: 600, min: 0 },
      items: 1,
      partialVisbile: false,
    },
  };

  const handleexclusive = async () => {
    setIslodingData(true);
    const id = "4";
    const response = await exclusiveofferswithId(id);

    if (response.remote === "success") {
      setTimeout(() => {
        setIslodingData(false);
      }, 3000);

      setTravelArray(response.data.data);
    } else {
    }
  };
  const handlTravelCategory = async () => {
    setIslodingData(true);

    const response = await TravelCategorySection();

    if (response.remote === "success") {
      setTimeout(() => {
        setIslodingData(false);
      }, 3000);
      setTravelCategory(response.data.data);
    } else {
    }
  };
  const handlTravelSection = async () => {
    setIslodingData(true);

    const response = await TravelSection();

    if (response.remote === "success") {
      setTimeout(() => {
        setIslodingData(false);
      }, 3000);
      setTravelPackage(response.data.data);
    } else {
    }
  };
  const handeltravelBanner = async () => {
    setIslodingData(true);

    const response = await TravelBannerSection();
    if (response.remote === "success") {
      setTimeout(() => {
        setIslodingData(false);
      }, 3000);
      setbannerList(response.data.data);
    } else {
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    handeltravelBanner();
    handleexclusive();
    handlTravelCategory();
    handlTravelSection();
  }, []);

  const categoryList = [];
  travelcategoryArray?.map((item, index) => {
    categoryList.push({
      id: item.id,
      subLink: item.name,
      subUrl: "/travels-tours",
    });
  });
  categoryList.push(
    { id: "2", subLink: "Cruises", subUrl: "/samadhi-cruises" }
    // { id: "5", subLink: "Fact about Malaysia", subUrl: "#" }
  );

  const [experienceListArray, SetExperienceListArray] = useState([]);
  const handleExperienceList = async () => {
    const id = "5";
    const response = await showExperienceIdsList(id);

    if (response.remote === "success") {
      SetExperienceListArray(response.data.data);
    } else {
    }
  };

  useEffect(() => {
    handleExperienceList();
  }, []);
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{bannerList[0]?.meta_title}</title>
        <meta name="description" content={bannerList[0]?.meta_description} />
      </Helmet>
      {isLoadingData ? <LoaderMain /> : ""}
      <div className=" travel-page-home travel">
        <div className=" main-bg">
          <div className="sub-header">
            <Row className="g-0">
              <Col
                lg={12}
                className="d-flex justify-content-md-center  justify-content-sm-center sub-header-title"
              >
                <h3>Travels</h3>
              </Col>
            </Row>
          </div>
          <Container maxWidth="xl" className="banner_container">
            <div
              className="banner-div "
              data-aos="fade-up"
              data-aos-duration="3000"
              // // data-aos-once="true"

              // style={{
              //   backgroundSize: "100% 100%",
              //   margin: "0 22px",
              //   backgroundImage: `url(${travelBanner2})`,

              //   backgroundRepeat: "no-repeat",
              // }}
            >
              {/* <h1
              style={{
                fontSize: "28px",
              }}
            >
              Tour overview video
            </h1> */}
              <img src={`${Offers_URL}${bannerList[0]?.image}`} alt="" />
            </div>
          </Container>
          <div className="page-description-div">
            <Container maxWidth="false">
              <Grid container justifyContent="center">
                <Grid
                  item
                  md={6}
                  className="page-description-data"
                  data-aos="fade-up"
                  data-aos-duration="3000"
                  // data-aos-once="true"
                >
                  <p>{bannerList[0]?.description}</p>
                </Grid>
              </Grid>
            </Container>
          </div>
          <div className="motor-bike-section">
            <Container maxWidth="xl">
              <div className="normal-card-section cursor-btn">
                <Grid container spacing={4}>
                  {MotorBikeArray.map((content, index) => {
                    var gotIndex = index % 2 == 0;
                    return gotIndex ? (
                      <>
                        <Grid
                          xs={12}
                          item
                          md={6}
                          className="normal-card-div"
                          key={content.id}
                          // data-aos-once="true"
                          data-aos="fade-up"
                          data-aos-duration="2000"
                          onClick={() => navigate(`/travels/${content.id}`)}
                        >
                          <OfferSlider {...content} />
                        </Grid>
                      </>
                    ) : (
                      <Grid
                        xs={12}
                        item
                        md={6}
                        className="normal-card-div"
                        key={content.id}
                        data-aos="fade-up"
                        data-aos-duration="1500"
                        // data-aos-once="true"
                        onClick={() => navigate(`/travels/${content.id}`)}
                      >
                        <OfferSlider {...content} />
                      </Grid>
                    );
                  })}
                </Grid>
              </div>
            </Container>
          </div>
          <div className="day-trips-section">
            <Container maxWidth="xl">
              <Grid className="text-center section-head">
                <h3 className="geting-text">Offers</h3>
              </Grid>
              <div className="btn-slider-div">
                <Carousel
                  autoPlaySpeed={1200}
                  arrows={true}
                  ssr={false}
                  partialVisbile={travelArray.length < 4 ? false : true}
                  // sliderClass="centerslider"
                  responsive={responsive}
                  autoPlay={false}
                  infinite={false}
                  className="mt-100"
                >
                  {travelArray?.map((item, index) => {
                    var gotIndex = index % 2 == 0;
                    return gotIndex ? (
                      <>
                        <div
                          key={index}
                          className="bottom-cards-div "
                          data-aos="fade-up"
                          data-aos-duration="1500"
                          // data-aos-once="true"
                          onClick={() => navigate(`/offers/${item.slug}`)}
                        >
                          <OfferSlider {...item} />
                        </div>
                      </>
                    ) : (
                      <div
                        key={index}
                        className="bottom-cards-div "
                        data-aos="fade-down"
                        data-aos-duration="1500"
                        // data-aos-once="true"
                        onClick={() => navigate(`/offers/${item.slug}`)}
                      >
                        <OfferSlider {...item} />
                      </div>
                    );
                  })}
                </Carousel>
              </div>
            </Container>
          </div>

          <div className="experience-div-section">
            <Container maxWidth="xl">
              <Grid className="text-center section-head">
                <h3>Stories</h3>
              </Grid>
              <Slider {...experienceSlide} className="experience-slider">
                {experienceListArray?.map((item, index) => (
                  <Grid className="testimonial-type-card">
                    <Grid
                      container
                      spacing={{ md: 6, xs: 2 }}
                      className="align-items-center d-flex "
                      data-aos="fade-up"
                      data-aos-duration="10000"
                      // data-aos-once="true"
                      alignItems="center"
                    >
                      <Grid item xs={12} md={4}>
                        <div>
                          {" "}
                          <Grid className="cards heading">
                            <h4>{item.title}</h4>
                            <p>{item.shortdescription}</p>
                            <Link to="#">Discover</Link>
                          </Grid>
                        </div>
                      </Grid>
                      <Grid item xs={12} md={8}>
                        <img src={`${Offers_URL}${item.banner}`} alt="" />
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              </Slider>
            </Container>
          </div>
        </div>
      </div>
    </>
  );
}
