import { Box, Container, Divider, Grid } from "@mui/material";
import { React, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { accoladesDetails } from "../../api/frontend";
import IMAGES from "../../assets/images";
import LoaderMain from "../../admin/component/loader";
import { Helmet } from "react-helmet";

const { accolBanner } = IMAGES;
const Accolcades = () => {
  const navigate = useNavigate();
  const [accolcadesdata, setAccoladesData] = useState([]);
  const [isLoadingData, setIslodingData] = useState(true);

  const handleAccolades = async () => {
    setIslodingData(true)
    const response = await accoladesDetails();

    if (response.remote === "success") {
      setTimeout(() => {
        setIslodingData(false);
      }, 3000);
      setAccoladesData(response.data.data);
    } else {
    }
  };
  useEffect(() => {
    handleAccolades();
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Boutique Hotels, Villas, Resorts & Residences | Samadhi Retreats
        </title>
        <meta
          name="description"
          content="Each Samadhi Retreats properties offer a unique sense of place, rooted in nature, culture and heritage. Discover more."
        />
      </Helmet>
      {isLoadingData ? <LoaderMain /> : ""}
      <div className="crms accolades ">
        <div className="experience-bg pb-200">
          <Container maxWidth="xl">
            <div className="crms-heading position-relative  ">
              <h3 className="font-62">Accolades</h3>
            </div>
          </Container>
          <Container maxWidth="xl" className="banner_container">
            <div
              className="banner-div "
              data-aos="fade-up"
              data-aos-duration="3000"
            >
              <img
                src={accolBanner}
                alt=""
                style={{ height: "500px", objectFit: "cover" }}
              />
            </div>
          </Container>
          <Container maxWidth="xl">
            <div className="mt-150" data-aos="fade-up" data-aos-duration="1500">
              <Grid container justifyContent="center">
                <Grid items xl={7} lg={9}>
                  <span className="">RESTAURANTS</span>

                  {accolcadesdata.map((item) => {
                    let tempArr = JSON.parse(item.text);
                    let newArry = tempArr.sort((a, b) => a.order - b.order);
                    return Number(item.category) === 1 ? (
                      <>
                        <div className="accolades-list-div mt-3">
                          <h4> {item.location}</h4>
                          <ul>
                            {newArry.map((items) => (
                              <>
                                <li>{items.text}</li>
                              </>
                            ))}
                          </ul>
                        </div>
                      </>
                    ) : (
                      ""
                    );
                  })}

                  <span>HOTELS| RESORT | VILLA</span>
                  {accolcadesdata.map((item) => {
                    let tempArr = JSON.parse(item.text);
                    let newArry = tempArr.sort((a, b) => a.order - b.order);
                    return Number(item.category) === 2 ? (
                      <>
                        <div className="accolades-list-div mt-3">
                          <h4> {item.location}</h4>
                          <ul>
                            {newArry.map((items) => (
                              <>
                                <li>{items.text}</li>
                                <li>{items.order}</li>
                              </>
                            ))}
                          </ul>
                        </div>
                      </>
                    ) : (
                      ""
                    );
                  })}
                </Grid>
              </Grid>
            </div>
          </Container>
        </div>
      </div>
    </>
  );
};
export default Accolcades;
